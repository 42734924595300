
<template>
    <div>
        <div class="box">
            <el-image src="http://222.168.10.38:8077/cczcimage/20231016/7209273a57c942828e58407cd805235a.png" class="box_top_img"></el-image>
            <el-image src="http://222.168.10.38:8077/cczcimage/20231012/90935cdbed384074a786b5e8953f7c7d.png" class="box_top_img_min"></el-image>
            <div class="box_title">留言箱</div>
            <el-form :model="dataForm" :rules="dataRule" class="box_form">
                <el-form-item label="标题:" prop="title"  label-width="100px">
                    <el-input v-model="dataForm.title" placeholder="标题"></el-input>
                </el-form-item>
                <el-form-item label="内容:" prop="content"  label-width="100px">
                    <el-input type="textarea" v-model="dataForm.content"></el-input>
                </el-form-item>
                <el-form-item label="邮箱:" prop="email"  label-width="100px">
                    <el-input v-model="dataForm.email" placeholder="邮箱"></el-input>
                </el-form-item>
                <el-form-item label="电话:" prop="phone"  label-width="100px">
                    <el-input v-model="dataForm.phone" placeholder="电话"></el-input>
                </el-form-item>
                <el-form-item label="单位:" prop="com"  label-width="100px">
                    <el-input v-model="dataForm.com" placeholder="单位"></el-input>
                </el-form-item>
                <el-form-item label="类型:" prop="type"  label-width="100px">
                    <el-select v-model="dataForm.type" placeholder="类型" clearable>
                        <el-option :label="data.dictLabel" v-for="data in typeList" :key="data.dictValue" :value ="data.dictValue">{{data.dictLabel}}</el-option>
                    </el-select>
                </el-form-item>
                <el-button style="width: 100%; color: white;font-weight: 700;background-color: #007cc7" @click="formOk()">确认留言</el-button>
            </el-form>
        </div>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                dataForm: {
                    id: '',
                    title: '',
                    content: '',
                    email: '',
                    phone: '',
                    com: '',
                    type: '',
                },
                typeList: []
            }
        },

        computed: {
            dataRule () {
                return {
                    title: [
                        { required: true, message: "必填项", trigger: 'blur' }
                    ],
                    content: [
                        { required: true, message: "必填项", trigger: 'blur' }
                    ],
                    phone: [
                        { required: true, message: "必填项", trigger: 'blur' }
                    ],
                    type: [
                        { required: true, message: "必填项", trigger: 'blur' }
                    ]
                }
            }
        },

        components: {

        },

        watch: {

        },

        mounted() {
            this.getTypeList()
        },

        methods: {
            getTypeList() {
                this.$axios.get(this.$httpUrl + '/CczcBox/cczcbox/type',{
                }).then(res => {
                    if (res.data.code != 0) {
                        return this.$message.error(res.data.data.msg);
                    }
                    this.typeList = res.data.data
                })
            },

            formOk () {
                if (this.dataForm.title == "") {
                    return this.$message.error("标题必填!")
                }
                if (this.dataForm.content == "") {
                    return this.$message.error("内容必填!")
                }
                if (this.dataForm.phone == "") {
                    return this.$message.error("电话必填!")
                }
                if (this.dataForm.type == "") {
                    return this.$message.error("类型必填!")
                }
                this.$axios.post(this.$httpUrl + '/CczcBox/cczcbox/', this.dataForm).then(({ data: res }) => {
                    if (res.code !== 0) {
                        return this.$message.error(res.msg)
                    }
                    this.$message({
                        message: "留言成功!",
                        type: 'success',
                        duration: 1500,
                    })
                    this.dataForm  = {
                        id: '',
                        title: '',
                        content: '',
                        email: '',
                        phone: '',
                        com: '',
                        type: '',
                    }
                }).catch(() => {})
            }
        }
    }
</script>


<style scoped>
    .box {
        position: relative;
    }

    .box_top_img {
        margin-top: 0px;
        margin-left: 0px;
        width: 100%;
        height: auto;
    }

    .box_top_img_min{
        position: absolute;
        width: 25%;
        height: auto;
        top: 200px;
        left: 37.5%;
    }

    .box_title {
        margin-top: 50px;
        text-align: center;
        font-size: 30px;
        font-weight: 700;
    }

    .box_form {
        margin-top: 50px;
        width: 40%;
        margin-left: 30%;
        margin-bottom: 50px;
    }
</style>
